<template>
    <el-main>
        <el-form class="set-order" ref="form" :model="form" label-width="120px" :rules="rules">
            <el-form-item class="item" label="分类名称：" prop="classify_name">
                <el-input v-model="form.classify_name"></el-input>
                <span class="sp2">分类名称建议12符号/6字以内</span>
            </el-form-item>
            <el-form-item class="item" label="组内权重：">
                <el-input type="number" :min="0" placeholder="请输入内容" v-model="form.sort"></el-input>
                <span class="sp2">数字越大，排名越靠前；如为空，排序顺序为添加时间</span>
            </el-form-item>
            <el-form-item class="item" label="是否显示：">
                <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <Preservation @preservation="onSubmit('form')">
                <router-link to="/repair/service/list" slot="return" v-if="form.id" style="margin-right: 10px">
                    <el-button>返回</el-button>
                </router-link>
            </Preservation>
        </el-form>
    </el-main>
</template>
  
<script>
import Preservation from '@/components/preservation';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
export default {
    components: {
        Preservation,
    },
    data () {
        return {
            form: {
                id: 0,
                is_show: 1,
                classify_name: '',
                sort: ''
            },
            rules: {
                classify_name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
                sort: [{ required: true, message: '请填写组内权重', trigger: 'blur' }],
            },
        };
    },
    created () {
        if (this.$route.query.info) {
            let data = JSON.parse(this.$route.query.info);
            this.form.is_show = data.is_show;
            this.form.classify_name = data.classify_name;
            this.form.sort = data.sort;
            this.form.id = data.id;
        }
    },
    methods: {
        onSubmit (form) {
            this.$refs[form].validate(valid => {
                if (valid) {
                    let data = {
                        is_show: this.form.is_show,
                        sort: this.form.sort,
                        classify_name: this.form.classify_name
                    };
                    if (this.form.id) data.id = this.form.id;
                    let url = data.id ? this.$api.repair.service.ClassifyEdit : this.$api.repair.service.ClassifyAdd;
                    this.$axios.post(url, data).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`${this.form.id ? '编辑成功' : '添加成功'}`);
                            this.$router.push('/repair/service/typelist');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    height: 100%;
    padding: 50px 0 0 100px;
    background: #fff;

    .set-order {
        width: 1000px;

        .item {

            .el-input,
            .el-select,
            .el-cascader {
                width: 500px;
            }

            .sp2 {
                margin-left: 20px;
                color: #999;
            }
        }

        .type-img {
            align-items: flex-start;

            span {
                margin-top: 10px;
                margin-left: 0 !important;
            }
        }
    }
}
</style>
  